import { Injectable } from "@angular/core";
import { ISorting } from "src/app/model/sorting";
import { CListService } from "src/app/common/services/_list.service";

@Injectable()
export class CArticlesListService extends CListService {
    public override sorting: ISorting = {by: "date", dir: -1};
    public override filter = {    
        id: null,    
        name: "",         
        from: undefined,
        to: undefined,
        date: undefined, 
        dateMode: "period", // period | day
    };

    public override filterChanged(): boolean {
        return this.filter.id !== null || this.filter.name !== "" || this.filter.from !== undefined || this.filter.to !== undefined || this.filter.date !== undefined;
    }

    public override filterReset(): void {
        this.filter = {
            id: null,
            name: "",    
            from: undefined,
            to: undefined, 
            date: undefined,       
            dateMode: this.filter.dateMode,
        };
    }  
}
